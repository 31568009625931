import { MouseEvent } from 'react'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

import { IconButton } from './icon-button'
import { CartIcon } from './icons/svg'
import { selectors } from '@/common/constants/selectors-constants'

export type ObsoleteAddToCartButtonProps = {
  disabled: boolean
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void
}

export function ObosoleteAddToCartButton({
  disabled,
  onClick,
}: ObsoleteAddToCartButtonProps): JSX.Element {
  const t = useTranslations('Product')

  return (
    <IconButton
      title={t('common.button.addToCart')}
      data-test={selectors.PDP.addToCartRecom}
      className={twJoin('absolute m-0 left-0 bottom-[-2px]', 'w-11 h-11')}
      variant="shadow"
      disabled={disabled}
      onClick={onClick}
      aria-label={t('common.button.addToCart')}
    >
      <CartIcon width="32px" height="32px" fill="#ffffff" />
    </IconButton>
  )
}
