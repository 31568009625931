import { CSSProperties } from 'react'
import { cva } from 'class-variance-authority'
import Image from 'next/image'
import { twJoin, twMerge } from 'tailwind-merge'

const variantsConfig = {
  variants: {
    position: {
      'top-left': ['top-[20px]'],
      'top-right': ['right-1', '-[20px]'],
      'middle-left': ['top-[50%]', '-translate-y-1/2'],
      'middle-right': ['right-1', 'top-[50%]', '-translate-y-1/2'],
      'bottom-left': ['bottom-[5%]'],
      'bottom-right': ['right-1', 'bottom-[5%]'],
      'top-middle': ['left-[50%]', 'top-[20px]', '-translate-x-1/2'],
      'bottom-middle': ['left-[50%]', 'bottom-[5%]', '-translate-x-1/2'],
      middle: [
        'top-[50%]',
        '-translate-y-1/2',
        'left-[50%]',
        '-translate-x-1/2',
      ],
    },
  },
}
const variants = cva(['absolute'], variantsConfig)
type PositionType = keyof typeof variantsConfig['variants']['position']

export interface ImageLabelProps {
  image?: string | null | undefined
  is_visible?: boolean | null | undefined
  name?: string | null | undefined
  position?: string | null | undefined
  size?: string | null | undefined
}

export interface ImageLabelsProps {
  labels?: ImageLabelProps[]
  galleryOffset?: boolean
  recommendedOffset?: boolean
}

export const ImageLabels = (props: ImageLabelsProps) => {
  const { labels, galleryOffset = false, recommendedOffset = false } = props

  return (
    <div className="absolute h-full w-full top-0 left-0 pointer-events-none">
      <div className="relative h-full">
        {labels?.map((label) => {
          const { name, image, size } = label
          const position = (label.position ?? 'middle') as PositionType
          return (
            <div
              key={image}
              className={twMerge(variants({ position }))}
              style={labelStyle({
                galleryOffset,
                recommendedOffset,
                position,
                size,
              })}
            >
              <div className="relative w-full h-full">
                <Image
                  src={image ?? ''}
                  alt={name ?? 'image not found'}
                  className={twJoin(
                    galleryOffset ? 'max-h-none' : 'max-h-[40px]',
                    'w-auto',
                  )}
                  priority
                  width={400}
                  height={400}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

interface LabelStyleProps {
  galleryOffset: boolean
  recommendedOffset: boolean
  position: PositionType
  size: string | null | undefined
}

const labelStyle = ({
  galleryOffset,
  recommendedOffset,
  position,
  size,
}: LabelStyleProps): CSSProperties => ({
  ...(size && galleryOffset ? { width: `${size}%` } : {}),
  maxWidth: '50%',
  ...(galleryOffset && position.includes('middle-')
    ? { top: 'calc(50% + 40px)' }
    : {}),
  ...(recommendedOffset && position.includes('bottom-left')
    ? { bottom: 'calc(50% - 55px)' }
    : {}),
})
